import Box from '@mui/material/Box';
import AppBar from '@mui/material/AppBar';
import Toolbar from '@mui/material/Toolbar';
import MenuIcon from '@mui/icons-material/Menu';
import IconButton from '@mui/material/IconButton';
import { connect, useDispatch } from 'react-redux'
import { Login } from './features/login/Login';
import Drawer from '@mui/material/Drawer';
import List from '@mui/material/List';
import CollectionsIcon from '@mui/icons-material/Collections';
import CardMembershipIcon from '@mui/icons-material/CardMembership';
import TerminalIcon from '@mui/icons-material/Terminal';
import Divider from '@mui/material/Divider';
import ListItem from '@mui/material/ListItem';
import ListItemIcon from '@mui/material/ListItemIcon';
import ListItemText from '@mui/material/ListItemText';
import ArticleIcon from '@mui/icons-material/Article';
import LogoutIcon from '@mui/icons-material/Logout';
import React from 'react';
import logo from './images/OriginsLogoSmall.svg';
import {
    logout
  } from './features/login/loginSlice';
import {
  Outlet,
  NavLink
} from "react-router-dom";

const drawerWidth = 240;

function Layout(login, props) {
  const { window } = props;

  const [mobileOpen, setMobileOpen] = React.useState(false);

  const handleDrawerToggle = () => {
    setMobileOpen(!mobileOpen);
  };  
  const dispatch = useDispatch();
  const drawer = (<div>
    <List>
        <ListItem button key="collections" component={NavLink} to="/collections">
          <ListItemIcon>
            <CollectionsIcon style={{color: 'white'}} />
          </ListItemIcon>
          <ListItemText primary="Collections" />
        </ListItem>  
        <ListItem button key="subscriptions" component={NavLink} to="/subscriptions">
          <ListItemIcon>
            <CardMembershipIcon style={{color: 'white'}} />
          </ListItemIcon>
          <ListItemText primary="Subscriptions" />
        </ListItem>

        <ListItem button key="commands" component={NavLink} to="/commands">
          <ListItemIcon>
            <TerminalIcon style={{color: 'white'}} />
          </ListItemIcon>
          <ListItemText primary="Commands" />
        </ListItem>      

        <ListItem button key="exports" component={NavLink} to="/exports">
          <ListItemIcon>
            <ArticleIcon style={{color: 'white'}} />
          </ListItemIcon>
          <ListItemText primary="Exports" />
        </ListItem>                    
    </List>
    <Divider />
    <List>
        <ListItem button key="logout" onClick={() => { console.log('logout'); dispatch(logout()); }}>
          <ListItemIcon>
            <LogoutIcon style={{color: 'white'}} />
          </ListItemIcon>
          <ListItemText primary="Logout" />
        </ListItem>                     
    </List>
  </div>)
  
  const container = window !== undefined ? () => window().document.body : undefined;
  return (
    <div>
      {!login.accessToken && <Login />}
      {login.accessToken && <div>

          <AppBar
              position="fixed"
              sx={{
                display: { xs: 'block', sm: 'none' },
                width: { sm: `calc(100% - ${drawerWidth}px)` },
                ml: { sm: `${drawerWidth}px` },
              }}
            >
          <Toolbar>
            <IconButton
              color="inherit"
              aria-label="open drawer"
              edge="start"
              onClick={handleDrawerToggle}
              sx={{ mr: 2, display: { sm: 'none' } }}
            >
              <MenuIcon />
            </IconButton>

            <Box
              noValidate
              component="div"
              sx={{
                display: 'flex',
                flexDirection: 'row',
                width: '100%',
                justifyContent: 'center'
              }}
            >            
              <img src={logo} style={{ textAlign: 'center', margin: '5px 20px 5px 0' }} />
            </Box>
          </Toolbar>
        </AppBar>          
        <Drawer
          sx={{
            display: { xs: 'none', sm: 'block' },
            width: drawerWidth,
            flexShrink: 0,
            '& .MuiDrawer-paper': {
              width: drawerWidth,
              boxSizing: 'border-box',
              backgroundColor: "transparent"
            },
          }}
          className="menu"
          variant="permanent"
          anchor="left"
        >
          <img src={logo} style={{ margin: '20px' }} />
          <Divider />          
          {drawer}
        </Drawer>
        <Drawer
          container={container}
          variant="temporary"
          open={mobileOpen}
          onClose={handleDrawerToggle}
          ModalProps={{
            keepMounted: true, // Better open performance on mobile.
          }}
          sx={{
            display: { xs: 'block', sm: 'none' },
            '& .MuiDrawer-paper': { boxSizing: 'border-box', width: drawerWidth, backgroundColor: "#151515", color: "white" },
          }}
        >
          {drawer}
        </Drawer>

        <Outlet />
      </div>}
    </div>
  )
}

function mapStateToProps(state) {
    const { login } = state
    return { accessToken: login.accessToken }
  }
  
export default connect(mapStateToProps)(Layout)
  