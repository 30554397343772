import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import { BASE_SUBSCRIPTION_API_URL } from '../../config/configuration'
import { initializeApp } from '@firebase/app';
import axios from "axios";

const initialState = {
  items: null,
  loading: false,
  loaded: false,
};

export const loadSubscriptions = createAsyncThunk(
  'subscriptions/load',
  async (_, {getState}) => {
    const state = getState()

    const response = await axios.get(`${BASE_SUBSCRIPTION_API_URL}/admin/subscriptions?page=1&page_size=300`, {
      headers: {
        'x-origins-token': state.login.accessToken
      }
    })
    const json = response.data;
    return json
  }
);

export const toggleBanRequested = createAsyncThunk(
  'subscriptions/toggleBanRequested',
  async ({subscriptionId, actualValue, index}, {getState}) => {
    const state = getState()
    const value = actualValue ? false : true
    const response = await axios.patch(`${BASE_SUBSCRIPTION_API_URL}/admin/subscriptions/${subscriptionId}/ban?value=${value}`, null, {
      headers: {
        'x-origins-token': state.login.accessToken
      }
    })
    const json = response.data;
    return {
      index,
      value
    }
  }
);

export const toggleDesignValidation = createAsyncThunk(
  'subscriptions/toggleDesignValidation',
  async ({subscriptionId, actualValue, index}, {getState}) => {
    console.log(actualValue)
    const state = getState()
    const value = actualValue ? false : true
    await axios.patch(`${BASE_SUBSCRIPTION_API_URL}/admin/subscriptions/${subscriptionId}/design_validation?value=${value}`, null, {
      headers: {
        'x-origins-token': state.login.accessToken
      }
    })
    return {
      index,
      value
    }
  }
);

export const subscriptionsSlice = createSlice({
  name: 'login',
  initialState,
  reducers: {
  },
  extraReducers: (builder) => {
    builder
      .addCase(loadSubscriptions.pending, (state) => {
        state.loading = true;
      })
      .addCase(loadSubscriptions.fulfilled, (state, action) => {
        state.loading = false;
        state.loaded = true;
        state.items = action.payload.items;
      });
    builder
      .addCase(toggleBanRequested.pending, (state, action) => {
        state.items[action.meta.arg.index].banRequestedLoading = true
      })
      .addCase(toggleBanRequested.fulfilled, (state, action) => {
        delete state.items[action.meta.arg.index].banRequestedLoading
        state.items[action.payload.index].ban_requested = !state.items[action.payload.index].ban_requested
      });
    builder
      .addCase(toggleDesignValidation.pending, (state, action) => {
        state.items[action.meta.arg.index].designValidationLoading = true
      })
      .addCase(toggleDesignValidation.fulfilled, (state, action) => {
        delete state.items[action.meta.arg.index].designValidationLoading
        state.items[action.payload.index].design_validated = !state.items[action.payload.index].design_validated
      });      
  },
});

export default subscriptionsSlice.reducer;
