import { configureStore } from '@reduxjs/toolkit';
import loginReducer from '../features/login/loginSlice';
import commandReducer from '../features/commands/commandsSlice';
import subscriptionsReducer from '../features/subscriptions/subscriptionsSlice';
import collectionsReducer from '../features/collections/collectionsSlice';
import collectionsStatsReducer from '../features/collectionsstats/collectionsStatsSlice';
import counterReducer from '../features/counter/counterSlice';
import exportsReducer from '../features/exports/exportsSlice';

export const store = configureStore({
  reducer: {
    counter: counterReducer,
    login: loginReducer,
    subscriptions: subscriptionsReducer,
    collections: collectionsReducer,
    collectionsStats: collectionsStatsReducer,
    commands: commandReducer,
    exports: exportsReducer
  },
});
