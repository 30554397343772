import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import axios from "axios";
import { BASE_DATALAKE_API_URL } from '../../config/configuration'

const initialState = {
  items: null,
  total: null,
  loading: false,
  loaded: false,
  dialogOpen: false,
  currentSlug: null,
  currentType: null,
  page: 1,
  q: ""
};

export const loadCollections = createAsyncThunk(
  'collections/load',
  async ({page, q, filter}, {getState}) => {
    const state = getState()
    let url = `${BASE_DATALAKE_API_URL}/collections/type_ahead_search?q=${q}&page=${page}`
    if (filter === 'realtime') url += '&realtime_listings=true'
    const response = await axios.get(url, {
      headers: {
        'x-origins-token': state.login.accessToken
      }
    })
    const json = response.data;
    return json
  }
);

export const togglePriorityLane = createAsyncThunk(
  'collections/togglePriority',
  async ({slug, actualPriority, index}, {getState}) => {
    console.log(`toggle priority ${slug} ${actualPriority} ${index}`)
    const state = getState()
    const value = actualPriority == '1' ? false : true
    const response = await axios.patch(`${BASE_DATALAKE_API_URL}/admin/${slug}/priority_lane?priority_lane=${value}`, null, {
      headers: {
        'x-origins-token': state.login.accessToken
      }
    })
    const json = response.data;
    return {
      index,
      value
    }
  }
);


export const toggleRealtime = createAsyncThunk(
  'collections/toggleRealtime',
  async ({slug, actualValue, index}, {getState}) => {
    const state = getState()
    const value = actualValue ? false : true
    const response = await axios.patch(`${BASE_DATALAKE_API_URL}/admin/${slug}/realtime?realtime=${value}`, null, {
      headers: {
        'x-origins-token': state.login.accessToken
      }
    })
    const json = response.data;
    return {
      index,
      value
    }
  }
);

export const setTwitterHandle = createAsyncThunk(
  'collections/setTwitterHandle',
  async ({slug, twitterHandle, index}, {getState}) => {
    const state = getState()
    await axios.patch(`${BASE_DATALAKE_API_URL}/admin/${slug}/twitter`, {
        twitter: twitterHandle
      }, {
      headers: {
        'x-origins-token': state.login.accessToken
      }
    })

    return {
      index,
      twitterHandle
    }
  }
);

export const setClassification = createAsyncThunk(
    'collections/setClassification',
    async ({slug, categories, index}, {getState}) => {
        const state = getState()
        await axios.put(`${BASE_DATALAKE_API_URL}/admin/${slug}/classification`, {
            classification: categories
        }, {
            headers: {
                'x-origins-token': state.login.accessToken
            }
        })

        return {
            index,
            categories
        }
    }
);

export const toggleIgnored = createAsyncThunk(
  'collections/toggleIgnored',
  async ({slug, actualValue, index}, {getState}) => {
    const state = getState()
    const value = actualValue ? false : true
    const response = await axios.patch(`${BASE_DATALAKE_API_URL}/admin/${slug}/ignored?ignored=${value}`, null, {
      headers: {
        'x-origins-token': state.login.accessToken
      }
    })
    const json = response.data;
    return {
      index,
      value
    }
  }
);

export const collectionsSlice = createSlice({
  name: 'collections',
  initialState,
  reducers: {
  },
  extraReducers: (builder) => {
    builder
      .addCase(loadCollections.pending, (state) => {
        state.loading = true;
      })
      .addCase(loadCollections.fulfilled, (state, action) => {
        state.loading = false;
        state.loaded = true;
        state.items = action.payload.items;
        state.pages = action.payload.total_pages;
        state.page = action.meta.arg.page;
        state.q = action.meta.arg.q;
      })
      .addCase(togglePriorityLane.pending, (state, action) => {
        state.items[action.meta.arg.index].priorityLoading = true
      })
      .addCase(togglePriorityLane.fulfilled, (state, action) => {
        delete state.items[action.meta.arg.index].priorityLoading
        state.items[action.payload.index].priority_lane = action.payload.value ? '1' : '0'
      })
      .addCase(toggleIgnored.pending, (state, action) => {
        state.items[action.meta.arg.index].ignoredLoading = true
      })
      .addCase(toggleIgnored.fulfilled, (state, action) => {
        state.items[action.payload.index].ignored = action.payload.value
      })
      .addCase(toggleRealtime.pending, (state, action) => {
      })
      .addCase(toggleRealtime.fulfilled, (state, action) => {
        state.items[action.payload.index].realtime_listings = action.payload.value
      })
      .addCase(setTwitterHandle.fulfilled, (state, action) => {
        state.items[action.payload.index].twitter_username = action.payload.twitterHandle
      });
  },
});

export const { reduceOffset } = collectionsSlice.actions;
export default collectionsSlice.reducer;
