import React, { useState, useRef, forwardRef } from 'react';
import Box from '@mui/material/Box';
import { connect, useDispatch } from 'react-redux'
import {
  loadCollectionsDatas
} from './collectionsStatsSlice';
import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogTitle from '@mui/material/DialogTitle';
import { Line } from 'react-chartjs-2';
import {
  Chart as ChartJS,
  CategoryScale,
  LinearScale,
  PointElement,
  LineElement,
  Title,
  Tooltip as ChartJSTooltip,
  Legend,
} from 'chart.js';

ChartJS.register(
  CategoryScale,
  LinearScale,
  PointElement,
  LineElement,
  Title,
  ChartJSTooltip,
  Legend
);

export function CollectionsStats({onClose, slug, type, collectionsStats}) {
  const [dialogTitle, setDialogTitle] = React.useState("Stats");
  const [loaded, setLoaded] = React.useState(false);
  const dispatch = useDispatch();
  const options = {
    responsive: true,
    plugins: {
      legend: {
        display: false
      },
    },
  };

  const data = {
    labels: collectionsStats.labels,
    datasets: [
      {
        data: collectionsStats.datas,
        borderColor: '#9e1eb5',
        backgroundColor: '#9e1eb5',
      },
    ],
  };
  
  console.log('CollectionsStats()', slug, type);
  // 
  if (!loaded) {
    setDialogTitle(`${slug} — ${type} stats`)
    dispatch(loadCollectionsDatas({slug, type, offset:0}))
    setLoaded(true)
  }

  return (
    <Dialog
      maxWidth="xl"
      open={true}
      onClose={onClose}
    >

      <Box
        noValidate
        component="div"
        sx={{
          display: 'flex',
          flexDirection: 'row',
          m: 'auto',
          width: '100%',
        }}
      >
        <span style={{flexGrow: 1, marginTop: '20px', fontSize: '25px', textAlign: 'right', cursor: 'pointer'}}
              onClick={() => {
                dispatch(loadCollectionsDatas({slug:collectionsStats.dialogSlug, type:collectionsStats.dialogType, offset:collectionsStats.dialogOffset+14}))
              }}>&lt;</span>
        <DialogTitle style={{textTransform: 'uppercase'}}>
          {dialogTitle}
        </DialogTitle>
        <span style={{flexGrow: 1, marginTop: '20px', fontSize: '25px', cursor: 'pointer'}}
              onClick={() => {
                dispatch(loadCollectionsDatas({slug:collectionsStats.dialogSlug, type:collectionsStats.dialogType, offset:collectionsStats.dialogOffset-14}))
              }}>&gt;</span>
      </Box>
      <DialogContent>
        <Box
          noValidate
          component="div"
          style={{width: '80vw'}}
          sx={{
            display: 'flex',
            flexDirection: 'column',
            m: 'auto',
            width: 'fit-content',
          }}
        >
          <Line options={options} data={data} ref={(reference) => {
            console.log(reference)
          }} />
        </Box>
      </DialogContent>
      <DialogActions>
        <Button onClick={onClose}>Close</Button>
      </DialogActions>
    </Dialog>

  );
}


function mapStateToProps(state) {
  const { collectionsStats } = state
  return { collectionsStats }
}

export default connect(mapStateToProps)(CollectionsStats)
