console.log('window.location.hostname', window.location.hostname)

export const ENVIRONMENT = 
    window.location.hostname === 'admin.originsnft.io' ? 'PRODUCTION' : 
    window.location.hostname === 'localhost' ? 'LOCAL' : 'STAGING'

    export const BASE_SUBSCRIPTION_API_URL = 
    ENVIRONMENT === 'PRODUCTION' ? 'https://subscription.api.originsnft.io' : 
    ENVIRONMENT === 'LOCAL' ? 'http://localhost:5056' : 
                    'https://subscription-api.stage.originsnft.io';

export const BASE_DATALAKE_API_URL = 
    ENVIRONMENT === 'PRODUCTION' ? 'https://datalake.api.originsnft.io' : 
    ENVIRONMENT === 'LOCAL' ? 'http://localhost:5055' : 
                    'https://datalake-api.stage.originsnft.io';

                                      