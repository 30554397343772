import Box from '@mui/material/Box';
import Layout from './Layout';

import React from 'react';
import {
  Routes,
  Route,
} from "react-router-dom";
import './App.css';
import Subscriptions from './features/subscriptions/Subscriptions';
import Collections from './features/collections/Collections';
import Commands from './features/commands/Commands';
import Exports from './features/exports/Exports';

function App() {
  
  return (
      <div className="App">

        <Routes>
          <Route path="/" element={<Layout />}>
            <Route index element={<Home />} />
            <Route path="collections" element={<Collections />} />
            <Route path="commands" element={<Commands />} />
            <Route path="exports" element={<Exports />} />
            <Route path="subscriptions" element={<Subscriptions />} />
          </Route>
        </Routes>
      </div>
  );
}
function Home() {
  return (
    <Box sx={{marginLeft: { xs: '0', sm: '240px' }, marginTop: { xs: '80px', sm: '0' }}}>
      <h2>Home</h2>
      <div style={{margin: '30px', textAlign: 'left'}}>Use the left panel to navigate through the features you're granted for.</div>
    </Box>
  );
}

export default App
