import React, { useState, useRef } from 'react';
import { useDispatch } from 'react-redux';
import {
  submitForm,
  submitForgotPasswordForm
} from './loginSlice';
import logo from '../../images/OriginsLogoSmall.svg';

export function Login() {
  const dispatch = useDispatch();
  const [formType, setFormType] = useState("signin");
  
  const email = useRef()
  const password = useRef()

  return (
    <div>
      <div data-animation="default" data-collapse="medium" data-duration="400" data-easing="ease" data-easing2="ease" role="banner" className="navbar w-nav">
        <img src={logo} className="navbar-image" />
      </div>
      
      <div className="login-card">
          <h1 className="login-card-h1">Admin Login</h1>
          <h2 className="login-card-h2">WARNING: This page is for authorized Origins Builders only.</h2>
          <div className="form-block w-form">
            <form id="login-form" name="login-form" className="form" 
              onSubmit={(e) => {
                e.preventDefault()
                dispatch(submitForm({formType, login: email.current.value, password: password.current.value}))
              }}>
                <div className="login-card-form-radio-buttons-div">
                    <label className="login-card-form-radio-button-field w-radio" htmlFor="sign-in">
                        Sign-in 
                        <input type="radio" name="signinup" id="sign-in" value="signin" checked={formType === 'signin'} onChange={(event) => setFormType(event.target.value)} className="w-form-formradioinput login-card-form-radio-button w-radio-input" />
                    </label>
                    <label className="login-card-form-radio-button-field w-radio" htmlFor="sign-up">
                        Sign-up 
                        <input type="radio" name="signinup" id="sign-up" value="signup" checked={formType === 'signup'} onChange={(event) => setFormType(event.target.value)} className="w-form-formradioinput login-card-form-radio-button w-radio-input" />
                    </label>
                </div>
                <label htmlFor="email" className="login-card-form-field-label">Username</label>
                <input type="text" className="login-card-form-text-field w-input" name="email" placeholder="" id="email" ref={email} />
                <label htmlFor="password" className="login-card-form-field-label">Password</label>
                <input type="password" className="login-card-form-text-field w-input" name="password" placeholder="" id="password" ref={password} />
                <input type="submit" value="Submit" className="button-primary w-button" />
                <button onClick={(e) => { e.preventDefault(); console.log(e); dispatch(submitForgotPasswordForm({login: email.current.value})); }} style={{backgroundColor: 'transparent', color: '#b73bce'}}>Forgot password</button>
            </form>
          </div>
      </div>      
    </div>
  );
}
