import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import axios from "axios";

const initialState = {
  loading: false,
  currentJobId: null,
  intervalId: null,
  result: "",
  running: false,
  lineOffset: 0
};

export const triggerAndWatch = ({fetchType, slug}) => async (dispatch, getState) => {
  const r = await dispatch(triggerCommand({fetchType, slug}))
  
  do {
    const state = getState()
    await new Promise(r => setTimeout(r, 2000));
    await dispatch(watchCommand({fetchType, jobId: r.payload, lineOffset: state.commands.lineOffset}))
    console.log(state.commands)
    if (!state.commands.running) break;
  } while (true)
  return
}


export const triggerCommand = createAsyncThunk(
  'commands/trigger',
  async ({fetchType, slug}, {getState}) => {
    console.log(fetchType, slug)
    const state = getState()
    if (fetchType === 'graphql') {
      const response = await axios.post(`https://ondemand-graphql-worker.originsnft.io/submit_job?slug=${slug}`, {
        headers: {
          'x-origins-token': state.login.accessToken
        }
      })
      const id = response.data;
      return id      
    } else {
      const response = await axios.post(`https://datalake.api.originsnft.io/admin/trigger/fetch/${fetchType}/${slug}`, {
        headers: {
          'x-origins-token': state.login.accessToken
        }
      })
      const id = response.data;
      return id
    }
  }
);


export const watchCommand = createAsyncThunk(
  'commands/watch',
  async ({fetchType, jobId, lineOffset}, {getState}) => {
    const state = getState();
    if (fetchType === 'graphql') {
      const response = await axios.get(`https://ondemand-graphql-worker.originsnft.io/logs/logs-${jobId}`, {
        headers: {
          'x-origins-token': state.login.accessToken
        }
      })
      const content = response.data;
      return content
    } else {
      const response = await axios.get(`https://datalake.api.originsnft.io/admin/logs/${jobId}?lineOffset=${lineOffset}`, {
        headers: {
          'x-origins-token': state.login.accessToken
        }
      })
      const content = response.data;
      return content
    }
  }
);

export const commandsSlice = createSlice({
  name: 'commands',
  initialState,
  reducers: {
  },
  extraReducers: (builder) => {
    builder
      .addCase(triggerCommand.pending, (state) => {
        state.loading = true;
        state.result = "Calling datalake, hang on tight!<br/>"
      })
      .addCase(triggerCommand.fulfilled, (state, action) => {
        state.loading = false;
        state.currentJobId = action.payload
        state.running = true
        console.log('currentJobId', state.currentJobId)
      })
      .addCase(watchCommand.fulfilled, (state, action) => {
        if (action.meta.arg.fetchType === 'graphql') {
          state.result = action.payload.split("\n").join("<br/>")
          if (action.payload.indexOf('ended graphql worker') > -1) {
            state.running = false
            state.result += "<br/>Ended!"  
          }
          return
        }
        if (action.payload == 'not running') {
          state.running = false
          state.result += "<br/>Ended!"
          return
        }
        state.running = true
        console.log('watch result', action.payload)
        let lines = typeof action.payload == 'string' ? action.payload.split("\n") : [JSON.stringify(action.payload)];
        let lineCount = 0
        for (const line of lines) {
          if (line.trim() === '') continue
          lineCount++
          if (line.substring(0, 1) == '{') {
              let json = JSON.parse(line)
              if (json.message.indexOf("exception") > -1) json.levelname = "ERROR"
              if (json.message.indexOf("Successfully") > -1 || json.message.indexOf(`Ended `) > -1) json.levelname = "SUCCESS"
              state.result += `<span class="${json.levelname}">[${json.asctime}]: ${json.message}</span><br/>`
          } else {
            state.result += `<span>${line}</span><br/>`
          }
        }
        state.lineOffset += lineCount
      })
  },
});

export default commandsSlice.reducer;
