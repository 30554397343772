import MenuItem from '@mui/material/MenuItem';
import { outlinedInputClasses } from "@mui/material/OutlinedInput";
import { inputLabelClasses } from "@mui/material/InputLabel";
import TextField from '@mui/material/TextField';
import React, { useState, useRef } from 'react';
import Box from '@mui/material/Box';
import { connect, useDispatch } from 'react-redux'
import {
  triggerAndWatch
} from './commandsSlice';
import Button from '@mui/material/Button';
import { styled } from '@mui/material/styles';

export function Commands({commands}) {
  const dispatch = useDispatch()
  const StyledTextField = styled(TextField)({
    [`& .${outlinedInputClasses.root} .${outlinedInputClasses.notchedOutline}`]: {
      borderColor: "#9e1eb5"
    },
    [`& .${outlinedInputClasses.input}`]: {
      color: "#9e1eb5"
    },
    [`& .${inputLabelClasses.outlined}`]: {
      color: "#9e1eb5"
    },
    [`&:hover .${outlinedInputClasses.input}`]: {
      color: "#9e1eb5"
    },
    [`&:hover .${inputLabelClasses.outlined}`]: {
      color: "#9e1eb5"
    },
    [`&:hover .${outlinedInputClasses.root} .${outlinedInputClasses.notchedOutline}`]: {
      borderColor: "#9e1eb5"
    },
  });
  const types = [{
    value: "metadata",
    label: "Opensea (metadatas)"
  },
  {
    value: "sales",
    label: "Opensea (sales)"
  },
  {
    value: "assets",
    label: "Opensea (assets and listings)"
  },
  {
    value: "graphql",
    label: "GraphQL (assets and listings)"
  }]
  const [type, setType] = React.useState('metadata');
  const [slugValue, setSlugValue] = React.useState('');
  const slug = useRef()
  return (
    <Box sx={{marginLeft: { xs: '0', sm: '240px' }, marginTop: { xs: '80px', sm: '0' }}}>
      <h2>COMMANDS</h2>
      <div style={{textAlign: 'left', margin: '30px'}}>
      <Box
        noValidate
        component="div"
        sx={{
          display: 'flex',
          flexDirection: 'row',
          m: 'auto',
          width: '100%',
        }}
      >
        <StyledTextField label="Enter a slug" variant="outlined" defaultValue={slugValue} inputRef={slug} id="slugInput" />
        <StyledTextField select label="Job type" value={type} variant="outlined" 
          style={{marginLeft: '10px'}} 
          onChange={(event) => {
            setType(event.target.value);
          }}>
          {types.map((option) => (
            <MenuItem key={option.value} value={option.value}>
              {option.label}
            </MenuItem>
          ))}
        </StyledTextField>
        <Button variant="contained" style={{marginLeft: '10px'}}
          onClick={() => {
            const fetchType = type
            setSlugValue(slug.current.value)
            dispatch(triggerAndWatch({fetchType, slug: slug.current.value}))
          }}>Trigger</Button>
      </Box>
        <div dangerouslySetInnerHTML={{ __html: commands.result}} style={{marginTop: '30px', whiteSpace: 'nowrap'}}>
        </div>
      </div>
    </Box>
  );

}


function mapStateToProps(state) {
  const { commands } = state
  return { commands }
}

export default connect(mapStateToProps)(React.memo(Commands))
