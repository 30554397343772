import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import { initializeApp } from '@firebase/app';
import axios from "axios";
import { getAuth, signInWithEmailAndPassword, createUserWithEmailAndPassword, sendPasswordResetEmail} from '@firebase/auth';
import { BASE_SUBSCRIPTION_API_URL } from '../../config/configuration';


const firebaseConfig = {
  apiKey: "AIzaSyArlJ0eq1cll_2uWn2k9lTjBz5vqNUWDI4",
  authDomain: "originsnft-admin.firebaseapp.com",
  databaseURL: "https://originsnft-admin-default-rtdb.firebaseio.com",
  projectId: "originsnft-admin",
  storageBucket: "originsnft-admin.appspot.com",
  messagingSenderId: "974871594680",
  appId: "1:974871594680:web:93918344fa7775cc46164d",
  measurementId: "G-8L12V8S2GT"
};
const app = initializeApp(firebaseConfig);
const auth = getAuth();

const initialState = {
  // TODO validate token
  accessToken: localStorage.getItem('origins-token'),
  loading: false,
};

export const submitForgotPasswordForm = createAsyncThunk(
  'login/submitForgotPasswordForm',
  async ({login}) => {
    console.log('forgot password', login)
    try {
      await sendPasswordResetEmail(auth, login)
      alert('an email containing reset instruction has been sent to the provided address')
    } catch (err) {
      alert(err)
    }
  }
);

export const submitForm = createAsyncThunk(
  'login/submitForm',
  async ({formType, login, password}) => {
    console.log(formType)
    if (formType === 'signin') {
      return await signin({login, password})
    } else {
      let userCredentials;
      try {
          userCredentials = await createUserWithEmailAndPassword(auth, 
                  login, 
                  password)
      } catch (err) {
          if (err.code !== "auth/email-already-in-use") {
            console.log("Create user error: ", err)
            // TODO better error handling
            alert(err)
            return;
          } else {
            return await signin({login, password})
          }
      }
      const response = await axios.post(`${BASE_SUBSCRIPTION_API_URL}/auth/token/firebase`, {
          token: userCredentials.user.accessToken
      })
      const json = response.data;
      console.log("Response json", json);
      return json      
    }
  }
);

async function signin({login, password}) {
  try {
    const userCredentials = await signInWithEmailAndPassword(auth, 
      login, 
      password)
    const response = await axios.post(`${BASE_SUBSCRIPTION_API_URL}/auth/token/firebase`, {
        token: userCredentials.user.accessToken
    })
    const json = response.data;
    console.log("Response json", json);
    return json
  } catch (err) {
    console.log(err)
    if (err.toString() === 'invalid role') {
      alert('invalid role, ask an admin to have an access granted')
    } else {
      alert(err)
    }
  }
}

export const loginSlice = createSlice({
  name: 'login',
  initialState,
  reducers: {
    logout: (state) => {
      console.log("logout()")
      // TODO clear firebase session
      localStorage.removeItem('origins-token')
      state.accessToken = null;
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(submitForm.pending, (state) => {
        state.loading = true;
      })
      .addCase(submitForm.fulfilled, (state, action) => {
        state.loading = false;
        const payload = action.payload
        if (payload.roles?.some(r => "ADMIN")) {
          state.accessToken = payload.session.access_token
          localStorage.setItem('origins-token', state.accessToken)
          console.log(action.payload)
        } else {
          // TODO better error handling
          if (payload.result === 'failure') {
            alert(payload.message)
          } else {
            alert("invalid role, ask an administrator to get authorized")
          }
        }
      });
  },
});

export const { logout } = loginSlice.actions;

export default loginSlice.reducer;
