import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import axios from "axios";

const initialState = {
  open: false,
  loaded: false,
  statsLoading: false,
  statsLoaded: false,
  dialogTitle: null,
  dialogType: null,
  dialogSlug: null,
  dialogOffset: 0,
  datas: [],
  labels: [],
};

export const loadCollectionsDatas = createAsyncThunk(
  'collections/loadstats',
  async ({slug, type, offset}, {getState}) => {
    if (offset < 0) offset = 0
    const state = getState()
    const response = await axios.get(`https://datalake.api.originsnft.io/collections/${slug}/history?size=30&offset=${offset}`, {
      headers: {
        'x-origins-token': state.login.accessToken
      }
    })
    const json = response.data;
    return json
  }
);

export const collectionsStatsSlice = createSlice({
  name: 'login',
  initialState,
  reducers: {
  },
  extraReducers: (builder) => {
    builder
      .addCase(loadCollectionsDatas.pending, (state) => {
        state.statsLoading = true;
      })
      .addCase(loadCollectionsDatas.fulfilled, (state, action) => {
        state.statsLoading = false;
        state.statsLoaded = true;
        const t = action.meta.arg.type == 'holders' ? 'holder_count' : 
          action.meta.arg.type == 'listed' ? 'listed_count' : 
          'current_floor'
        console.log('aaction.meta.arg.type', t)
        state.dialogSlug = action.meta.arg.slug;
        state.dialogType = action.meta.arg.type;
        state.dialogOffset = action.meta.arg.offset;
        if (state.dialogOffset < 0) state.dialogOffset = 0;
        state.datas = action.payload.map(p => p[t]);
        state.labels = action.payload.filter(d => d[t] != null).map(d => d.fetch_date).reverse();
        console.log(state.datas)
      });      
  },
});

export default collectionsStatsSlice.reducer;
