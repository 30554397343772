import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import { inputLabelClasses } from "@mui/material/InputLabel";
import { outlinedInputClasses } from "@mui/material/OutlinedInput";
import { styled } from '@mui/material/styles';
import TextField from '@mui/material/TextField';
import React, { useState, useRef } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import {
  exportData
} from './exportsSlice';

export default function Exports() {
  const dispatch = useDispatch();

  const StyledTextField = styled(TextField)({
    [`& .${outlinedInputClasses.root} .${outlinedInputClasses.notchedOutline}`]: {
      borderColor: "#9e1eb5"
    },
    [`& .${outlinedInputClasses.input}`]: {
      color: "#9e1eb5"
    },
    [`& .${inputLabelClasses.outlined}`]: {
      color: "#9e1eb5"
    },
    [`&:hover .${outlinedInputClasses.input}`]: {
      color: "#9e1eb5"
    },
    [`&:hover .${inputLabelClasses.outlined}`]: {
      color: "#9e1eb5"
    },
    [`&:hover .${outlinedInputClasses.root} .${outlinedInputClasses.notchedOutline}`]: {
      borderColor: "#9e1eb5"
    },    
  });  
  const input = useRef()
  return (
    <Box sx={{marginLeft: { xs: '0', sm: '240px' }}}>
      <h2>EXPORTS</h2>
      <div style={{margin: '30px auto', maxWidth: '70vw', textAlign: 'left'}}>
        <div>
          Enter slugs to export collection datas to google sheet.
        </div>
        <StyledTextField
            style={{marginTop: '10px', minWidth: '70vw'}}
            label="Slugs"
            multiline
            inputRef={input}
            rows={4}
          /><br/>
        <Button variant="contained" style={{marginTop: '10px'}}
          onClick={() => {
            dispatch(exportData({
              slugs: input.current.value.split("\n")
            }))
          }}>Trigger</Button>        
      </div>
    </Box>
  );
}
