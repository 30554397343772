import Box from '@mui/material/Box';
import LoadingButton from '@mui/lab/LoadingButton';
import CircularProgress from '@mui/material/CircularProgress';
import React, { useState, useRef } from 'react';
import { connect, useDispatch } from 'react-redux'
import {
  loadSubscriptions,
  toggleBanRequested,
  toggleDesignValidation
} from './subscriptionsSlice';
import { styled } from '@mui/material/styles';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import Paper from '@mui/material/Paper';

export function Subscriptions({subscriptions}) {
  console.log(subscriptions)
  const dispatch = useDispatch();
  if (!subscriptions.loaded && !subscriptions.loading) {
    dispatch(loadSubscriptions())
  }

  const StyledTableRow = styled(TableRow)(({ theme }) => ({
    '&:nth-of-type(odd)': {
      backgroundColor: 'transparent',
    },
    '&:nth-of-type(even)': {
      backgroundColor: 'transparent',
    }
  }));
  
  return (
    <Box sx={{marginLeft: { xs: '0', sm: '240px' }, marginTop: { xs: '80px', sm: '0' }}}>
      <h2>{subscriptions?.items?.length} SUBSCRIPTIONS</h2>
      
      <Box sx={{
        margin: { xs: '30px 10px 30px 10px', sm: '30px' }
      }}>
        {subscriptions.loading && <CircularProgress />}

        {!subscriptions.loading && <TableContainer component={Paper} sx={{
          backgroundColor: "transparent",
          paddingBottom: '30px'
        }}>
          <Table sx={{ minWidth: 650,
            '& .MuiTableCell-root': {
              backgroundColor: "transparent",
              color: "white",
              border: "1px solid #560f63"
            },
            '& .MuiTableCell-head': {
              color: "#9e1eb5"
            }
            }} aria-label="Subscriptions">
            <TableHead>
              <TableRow>
                <TableCell>Discord username</TableCell>
                <TableCell>Discord server (#)</TableCell>
                <TableCell>Plan</TableCell>
                <TableCell align="right">Subscribed at</TableCell>
                <TableCell align="right">Expire at</TableCell>
                <TableCell align="right">Request count</TableCell>
                <TableCell align="right">Joined server</TableCell>
                <TableCell>Last customization validation</TableCell>
                <TableCell align="right">Actions</TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {subscriptions?.items?.map((row, index) => (
                <StyledTableRow key={row.id}>
                  <TableCell component="th" scope="row">
                    {row.discord_username}
                  </TableCell>
                  <TableCell>{row.discord_guild_name}</TableCell>
                  <TableCell>{row.subscription_type}</TableCell>
                  <TableCell align="right">{new Date(row.created_at).toLocaleString()}</TableCell>
                  <TableCell align="right">{new Date(row.expire_at).toLocaleString()}</TableCell>
                  <TableCell align="right">{row.requests_count}</TableCell>
                  <TableCell align="right">{row.in_server ? 'yes' : 'no'}</TableCell>
                  <TableCell>
                    {row.design_validation_user && <div style={{marginBottom: '20px'}}>
                      {new Date(row.design_validation_date).toLocaleString()}<br/>
                      by&nbsp;{row.design_validation_user}
                    </div>}
                    <LoadingButton loading={row.designValidationLoading} variant="contained" size="small" onClick={() => {
                      dispatch(toggleDesignValidation({subscriptionId: row.id, actualValue: row.design_validated, index}))
                    }}>{row.design_validated ? 'Unvalidate' : 'Validate'}</LoadingButton>
                  </TableCell>
                  <TableCell>
                  <LoadingButton loading={row.banRequestedLoading} variant="contained" size="small" onClick={() => {
                      dispatch(toggleBanRequested({subscriptionId: row.id, actualValue: row.ban_requested, index}))
                    }}>{row.ban_requested ? 'Unban' : 'Ban'}</LoadingButton><br/>
                  </TableCell>
                </StyledTableRow>
              ))}
            </TableBody>
          </Table>
        </TableContainer>}
      </Box>
    </Box>
  );
}

function mapStateToProps(state) {
  const { subscriptions } = state
  return { subscriptions }
}

export default connect(mapStateToProps)(Subscriptions)
