import axios from "axios";
import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';

const initialState = {
};

export const exportData = createAsyncThunk(
  'exports/export',
  async ({slugs}, {getState}) => {
    const state = getState()
    const response = await axios.post(`https://datalake.api.originsnft.io/admin/google_sheet_export`, 
    slugs, 
    {
      headers: {
        'x-origins-token': state.login.accessToken
      }
    })
    const content = response.data;
    return content
  }
);

export const exportSlice = createSlice({
  name: 'export',
  initialState,
  reducers: {
  },
  extraReducers: (builder) => {
    builder
      .addCase(exportData.pending, (state) => {
      })
      .addCase(exportData.fulfilled, (state, action) => {
        console.log(action.payload.url)
        window.open(action.payload.url)
      });
  },
});

export default exportSlice.reducer;
